import { useEffect, useReducer, useState } from "react";
import { GetAttachId, GetMediasUrl } from "../../api/metashower";
import {
  GetAttachUrl,
  ManageClickType,
  MediaPage,
  MetaShowerGlobalMediasTyoe,
  ShowToastTips,
} from "../../dtos/metashower";
import message from "../message/action";

interface MediaIdList {
  attachmentId: number;
  mediaId: number;
}

export const useAction = (pageType: number) => {
  const [mediasUrlList, setMediasUrlList] = useState<GetAttachUrl[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalMsg, settotalMsg] = useState<number>(1);
  const [mediaIdList, setMediaIdList] = useState<MediaIdList[]>([]);
  const [loading, setLoading] = useState(false);

  interface PropsType {
    showDialog: boolean;
    uploadSuccess: boolean;
    clickType: string;
  }
  const initValue: PropsType = {
    showDialog: false,
    uploadSuccess: false,
    clickType: ManageClickType.Open,
  };

  const getAttachId = async () => {
    const attchIds = await GetAttachId({
      MediaType: pageType,
      PageIndex: pageIndex,
      PageSize: MediaPage.PageSize,
    });
    if (attchIds?.metaShowerGlobalMedias) {
      const newMediaIdList: MediaIdList[] = [];

      const attachmentIdList = attchIds?.metaShowerGlobalMedias.map(
        (item: MetaShowerGlobalMediasTyoe) => {
          newMediaIdList.push({
            attachmentId: item.attachmentId,
            mediaId: item.id,
          });
          return item.attachmentId;
        }
      );

      setMediaIdList(newMediaIdList);

      const total = Math.ceil(attchIds?.count / MediaPage.PageSize);
      settotalMsg(total);

      await GetMediasUrl({
        attachmentIds: attachmentIdList,
      }).then((res) => {
        if (res) {
          handlerNewArr(attchIds?.metaShowerGlobalMedias, res);
        }
      });
    }
  };

  const handlerNewArr = (
    ids: MetaShowerGlobalMediasTyoe[],
    list: GetAttachUrl[]
  ) => {
    const newArr = list.map((listItem) => {
      const getIndex = ids.filter((idsItem) => {
        if (listItem?.id === idsItem?.attachmentId) {
          return idsItem;
        }
      });
      return { ...listItem, title: getIndex[0].title };
    });
    setMediasUrlList(newArr);
  };

  const reducer = (
    preState: PropsType,
    action: {
      type: string;
      clickType: string;
      showDialog: boolean;
    }
  ) => {
    const { type, clickType, showDialog } = action;
    switch (type) {
      default:
        return preState;
      case "clickAction":
        return {
          ...preState,
          showDialog,
          clickType,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, initValue);
  useEffect(() => {
    if (
      state.clickType === ManageClickType.Close ||
      (state.clickType === ManageClickType.Open && state.showDialog)
    )
      return;
    if (state.clickType === ManageClickType.Upload) {
      getAttachId();
      return message.toast(ShowToastTips.success, 1500, "success", true);
    }
    getAttachId();
  }, [state.clickType, pageIndex]);

  const getPageNum = (_event: React.ChangeEvent<unknown>, page: number) => {
    setPageIndex(page);
    dispatch({
      type: "clickAction",
      clickType: ManageClickType.Open,
      showDialog: false,
    });
  };

  return {
    mediasUrlList,
    state,
    dispatch,
    getPageNum,
    totalMsg,
    getAttachId,
    mediaIdList,
    loading,
    setLoading,
  };
};
