export interface IVideoParam {
  image: string
  name: string
}

export interface MedaiDataPageIndex {
  pageIndex: number
  pageSize: number
}

export enum MetaShowerMediaType {
  Avatar,
  Background,
}

export interface GlobalMediaDataParam {
  attachmentIds: number[]
  mediaType: MetaShowerMediaType
}

export interface MetaShowerGlobalMediaData {
  metaShowerGlobalMediaData: GlobalMediaDataParam[]
}

export interface MediaDataDto {
  count: number
  metaShowerGlobalMedias: GlobalMediaDataDto[]
}

export interface GlobalMediaDataDto {
  id: number
  uuid: string
  userId: number
  title: string
  attachmentId: number
  createdDate: string
  mediaType: MetaShowerMediaType
}

export interface IPostAttachmentParam {
  attachmentIds: number[]
}

export interface IAttachmentResponseDto {
  title: string
  createdDate: string
  fileName: string
  filePath: string
  fileSize: number
  fileUrl: string
  id: number
  uuid: string
  mediaId: number
}

export interface IParamSummaryDto {
  videoScale: string
  language: number
  avtarName: string
  avtarBackground: string
}

export interface IAchmentIdTitle {
  title: string
  attachmentId: number
}

export interface IMediaDataListProps {
  metaShowerMediaType: number
  mediaDataPages: number
  mediaDataPageIndex: {
    pageIndex: number
    pageSize: number
  }
  mediaData: IAttachmentResponseDto[]
  currentIndex: number
  isLoading: boolean
  attachmentId: number[]
  attachmentIdTitle: IAchmentIdTitle[]
  select: (id: number, index: number) => void
  mediaDataTotal: (count: number) => void
  handlePageIndex: (event: React.ChangeEvent<unknown>, page: number) => void
  setAttachmentId: (attachmentIdList: number[]) => void
  setIsLoading: (isLoad: boolean) => void
  setMediaTypeData: (res: IAttachmentResponseDto[]) => void
  setMediaTypeName: (title: string) => void
  setArrachmentIdTitle: (arr: IAchmentIdTitle[]) => void
}
