export enum MetaShowerBatchStatus {
  Pending = 10,
  Queuing = 11,
  InProgress = 20,
  Completed = 80,
}

export interface MetaShowerBatchDto {
  count: number;
  metaShowerBatches: MetaShowerBatches[];
}

export interface MetaShowerBatches {
  id: number;
  uuid: string;
  userId: number;
  videoUrl: string;
  status: MetaShowerBatchStatus;
  text: string;
  title: string;
  createdDate: string;
}

export interface AddMetaShowerCommand {
  uuid: string;
  text: string;
  title: string;
  language: number;
  mediaIds: number[];
}

export enum MetaShowerType {
  Avatar = 0,
  Background = 1,
}

export interface MediasDataType {
  count: number;
  metaShowerGlobalMedias: MetaShowerGlobalMediasTyoe[];
}

export interface MetaShowerGlobalMediasTyoe {
  id: number;
  uuid: string;
  userId: number;
  attachmentId: number;
  createdDate: string;
  mediaType: number;
  title: string;
}

export interface MediaType {
  mediatype: number;
}

export interface GetAttachUrl {
  id: number;
  uuid: string;
  createDate: string;
  fileUrl: string;
  fileName: string;
  fileSize: number;
  filePath: string;
  title?: string;
}

export interface GetMediaType {
  MediaType?: number;
  PageIndex: number;
  PageSize: number;
}

export enum MediaPage {
  PageIndex = 1,
  PageSize = 30,
}

export interface AttachmentId {
  attachmentIds: number[];
}

export interface UploadParams {
  ContentType: string;
  ContentDisposition: string;
  Headers: object;
  length: number;
  Name: string;
  FileName: string;
}

export interface PostMediaParams {
  attachmentIds?: AttachmentId;
  metaShowerGlobalMediaData?: {
    title?: string;
    attachmentIds: number[];
    mediaType: MetaShowerType;
    isPublic?: boolean;
  }[];
}

export enum ManageClickType {
  Open = "open",
  Close = "close",
  Upload = "upload",
}

export enum UploadTitle {
  BackgroundTitle = "上传背景",
  Avatar = "上传头像",
}

export enum ShowToastTips {
  success = "提交成功!",
  UploadImg = "请先上传图片",
  loading = "图片加载中...",
}

export enum LanguageVoiceType {
  MandarinMale = 1,
  MandarinFemale = 2,
  AmericanEnglishMale = 3,
  AmericanEnglishFemale = 4,
  CantoneseFemale = 6,
  JapaneseMale = 7,
  JapaneseFemale = 8,
}
