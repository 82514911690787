import { Box, CircularProgress, Fab, Pagination } from "@mui/material"
import { green } from "@mui/material/colors"
import { Link } from "react-router-dom"
import CheckIcon from "@mui/icons-material/Check"
import Autorenew from "@mui/icons-material/Autorenew"
import moment from "moment"
import {
  MetaShowerBatches,
  MetaShowerBatchStatus,
} from "../../../../dtos/metashower"
import { useAction } from "./hook"
import style from "./index.module.scss"
import { ModalVideo } from "../../../../components/modal"
import { DetailIcon } from "../../../../components/detail-icon"

const Project = () => {
  const {
    list,
    handleButtonClick,
    buttonRefresh,
    success,
    loading,
    clickVideo,
    videoUrl,
    isShowVideo,
    onCancel,
    onDetailIcon,
    clickDetailIcon,
    onDetail,
    videoText,
    clickDetail,
    clickIndex,
    totalMsg,
    getPageNum,
    setClickDetailIcon,
  } = useAction()

  const vedioComponent = (
    status: MetaShowerBatchStatus,
    key: number,
    item: MetaShowerBatches
  ) => {
    const detailIcon = (
      <DetailIcon
        onDetailIcon={() => onDetailIcon(key)}
        clickDetailIcon={clickDetailIcon}
        index={key}
        onDetail={() => onDetail(item.text)}
        clickIndex={clickIndex}
        setClickDetailIcon={setClickDetailIcon}
      />
    )

    switch (status) {
      case MetaShowerBatchStatus.Completed: {
        return (
          <div className={style.projectWrap} key={key}>
            <div onClick={() => clickVideo(item)} className={style.videoWrap}>
              <video className={style.videoBox} controls={false}>
                <source
                  src={item.videoUrl}
                  type="video/mp4"
                  className={style.video}
                />
              </video>
            </div>
            <div className={style.videoMessageWrap}>
              <div className={style.videoContextTop}>
                <span className={style.videoTitle}>{item.title}</span>
                {detailIcon}
              </div>
              <div className={style.videoContextBottom}>
                <span className={style.videoTime}>{`${moment
                  .utc(item.createdDate)
                  .local()
                  .format("MM-DD HH:mm")} 编辑·视频剪辑`}</span>
              </div>
            </div>
          </div>
        )
      }
      case MetaShowerBatchStatus.InProgress: {
        return (
          <div className={style.projectWrap} key={key}>
            <div onClick={() => clickVideo(item)} className={style.videoWrap}>
              <div className={style.pendingBox}>
                <span>生成中...</span>
              </div>
            </div>
            <div className={style.videoMessageWrap}>
              <div className={style.videoContextTop}>
                <span className={style.videoTitle}>{item.title}</span>
                {detailIcon}
              </div>
              <div className={style.videoContextBottom}>
                <span className={style.videoTime}>{`${moment
                  .utc(item.createdDate)
                  .local()
                  .format("MM-DD HH:mm")} 编辑·视频剪辑`}</span>
              </div>
            </div>
          </div>
        )
      }
      case MetaShowerBatchStatus.Queuing: {
        return (
          <div className={style.projectWrap} key={key}>
            <div onClick={() => clickVideo(item)} className={style.videoWrap}>
              <div className={style.pendingBox}>
                <span>队列中...</span>
              </div>
            </div>
            <div className={style.videoMessageWrap}>
              <div className={style.videoContextTop}>
                <span className={style.videoTitle}>{item.title}</span>
                {detailIcon}
              </div>
              <div className={style.videoContextBottom}>
                <span className={style.videoTime}>{`${moment
                  .utc(item.createdDate)
                  .local()
                  .format("MM-DD HH:mm")} 编辑·视频剪辑`}</span>
              </div>
            </div>
          </div>
        )
      }
      default: {
        return (
          <div className={style.projectWrap} key={key}>
            <div onClick={() => clickVideo(item)} className={style.videoWrap}>
              <div className={style.pendingBox}>
                <span>待生成...</span>
              </div>
            </div>
            <div className={style.videoMessageWrap}>
              <div className={style.videoContextTop}>
                <span className={style.videoTitle}>{item.title}</span>
                {detailIcon}
              </div>
              <div className={style.videoContextBottom}>
                <span className={style.videoTime}>{`${moment
                  .utc(item.createdDate)
                  .local()
                  .format("MM-DD HH:mm")} 编辑·视频剪辑`}</span>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <>
      <div className={style.box}>
        <div className={style.addAndSearch}>
          <div className={style.buttom}>
            <Link to={{ pathname: "/generation" }} className={style.addButtom}>
              <div className={`${"iconfont icon-jiahao"} ${style.addIcon}`} />
              <span className={style.addFont}>新建视频剪辑</span>
            </Link>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ m: 1, position: "relative" }}>
                <Fab
                  aria-label="save"
                  color="primary"
                  className={style.refreshText}
                  sx={buttonRefresh}
                  onClick={() => handleButtonClick()}
                >
                  {success ? <CheckIcon /> : <Autorenew />}
                </Fab>
                {loading && (
                  <CircularProgress
                    size={45}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: -1.5,
                      left: -2,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            </Box>
          </div>

          <div className={style.search}>
            <input className={style.searchInput} placeholder={"搜索项目名称"} />
            <div
              className={`${"iconfont icon-sousuoxiao"} ${style.searchIcon}`}
            />
          </div>
        </div>
        <div className={style.vedio}>
          {list?.map((item, key) => vedioComponent(item.status, key, item))}
          {list === null && (
            <div className={style.tipWrap}>
              <div className={style.tip}>暂无剪辑项目</div>
            </div>
          )}
        </div>
        {(list ?? "") !== "" ? (
          <div className={style.pagination}>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={totalMsg}
              onChange={getPageNum}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      {isShowVideo && (
        <ModalVideo videoUrl={videoUrl} onCancel={onCancel} type={"video"} />
      )}
      {clickDetail && (
        <ModalVideo videoText={videoText} onCancel={onCancel} type={"text"} />
      )}
    </>
  )
}
export default Project
