import { useAction } from "./hook";
import styles from "./styles.module.scss";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Input,
  Snackbar,
} from "@mui/material";
import { Cancel, Upload } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { ShowToastTips } from "../../dtos/metashower";

interface PropsType {
  pageType: number;
  showDialog: boolean;
  dialogTitle: string;
}

const UploadModal = (props: PropsType) => {
  const {
    uploadFile,
    inputVal,
    setInputVal,
    getContextVal,
    submitBtn,
    deleteImg,
    uploadImg,
    closeDialog,
    loading,
    setLoading,
    setIsPublic,
    isPublic,
  } = useAction(props.pageType);

  const { state } = getContextVal;
  const handleClose = () => {
    setLoading(false);
  };

  return state.showDialog ? (
    <div>
      <Dialog
        open={state.showDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        keepMounted
        disableEscapeKeyDown={true}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ padding: "1.5rem 1.5rem 1rem" }}
        >
          <div className={styles.tabs}>{props.dialogTitle}</div>
        </DialogTitle>
        <DialogContent>
          <div className={styles.inputBox}>
            <Input
              className={styles.enterInput}
              type="text"
              autoFocus={false}
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
              size={"small"}
              placeholder="请输入文件名"
            />
            <Button
              variant="contained"
              className={styles.uploadBtn}
              disableElevation
              component="label"
              sx={{
                color: "#fff",
                background: "#30CEA3",
              }}
            >
              上传
              <Upload />
              <input
                hidden
                accept={
                  props.dialogTitle === "上传背景"
                    ? "image/jpg,image/png"
                    : "image/png"
                }
                multiple
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  !!e.target.files && uploadFile(e)
                }
                id="uploadFile"
              />
            </Button>
          </div>
          <div className={styles.uploadBox}>
            {(uploadImg ?? "") !== "" ? (
              <div className={styles.avatarAdd}>
                <Cancel className={styles.icon} onClick={deleteImg} />
                <img src={uploadImg} alt="" className={styles.img} />
              </div>
            ) : (
              <div />
            )}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublic}
                onClick={() => setIsPublic(!isPublic)}
              />
            }
            label="是否选择公开"
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0.5rem 0.5rem 1rem" }}>
          <Button
            onClick={closeDialog}
            sx={{
              color: "#30cea3",
            }}
          >
            取消
          </Button>
          <Button
            onClick={submitBtn}
            sx={{
              color: "#30cea3",
            }}
          >
            提交
          </Button>
        </DialogActions>
        <Snackbar
          open={loading}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert variant="standard" icon={false}>
            <CircularProgress
              sx={{ color: "#7a7a7a", marginRight: "0.5rem" }}
              size={16}
            />
            {ShowToastTips.loading}
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  ) : (
    <div />
  );
};

export default UploadModal;
