import { ReactElement } from "react";
import Management from "../pages/management";
import Digitizing from "../pages/digitizing";
import Home from "../pages/home";
import Generation from "../pages/generation";
import Project from "../pages/home/components/project";
import Share from "../pages/home/components/share";

export interface IRouteItem {
  name: string;
  path: string;
  component: ReactElement;
  icon: string;
  children?: childrenProps[];
}

export interface childrenProps {
  name: string;
  path: string;
  component: ReactElement;
}

const routes: IRouteItem[] = [
  {
    name: "我的项目",
    path: "/home",
    icon: "iconfont icon-sort-full",
    component: <Home />,
    children: [
      {
        name: "我的项目",
        path: "/home/project",
        component: <Project />,
      },
      {
        name: "与我共享",
        path: "/home/share",
        component: <Share />,
      },
    ],
  },
  {
    name: "数字化身",
    path: "/digitizing",
    icon: "iconfont icon-sort-full",
    component: <Digitizing />,
  },
  {
    name: "背景管理",
    path: "/menagement",
    icon: "iconfont icon-24gf-folder",
    component: <Management />,
  },
  {
    name: "项目生成",
    path: "/generation",
    icon: "iconfont icon-send-plane-fill",
    component: <Generation />,
  },
];

export default routes;
