import { ListSharp } from "@mui/icons-material";
import { IconButton, MenuItem, MenuList } from "@mui/material";
import { DetailIconProperty } from "../../dtos/detail-icon";
import style from "./index.module.scss";
export const DetailIcon = (props: DetailIconProperty) => {
  const {
    onDetailIcon,
    clickDetailIcon,
    index,
    onDetail,
    clickIndex,
    setClickDetailIcon,
  } = props;
  return (
    <div
      className={style.buttonWrap}
      onBlur={(e) => {
        setClickDetailIcon(false);
      }}
    >
      <IconButton onClick={onDetailIcon} className={style.btn}>
        <ListSharp />
      </IconButton>

      {clickDetailIcon && index === clickIndex && (
        <MenuList className={style.menuWrap}>
          <MenuItem onMouseDown={onDetail} className={style.menuItemWrap}>
            详情
          </MenuItem>
        </MenuList>
      )}
    </div>
  );
};
