import style from "./index.module.scss"
import { Link, Outlet } from "react-router-dom"
import "../../fonts/iconfont.css"
import routes, { IRouteItem } from "../../routes"
import Icon from "../../components/icon"
import { useAction } from "./hook"

const MainPage = () => {
  const { routerIndex, setRouterIndex } = useAction()
  return (
    <div className={style.body}>
      <div className={style.navBar}>
        <div className={style.navItem}>
          <span className={style.titleFont}>我的</span>
          {routes.map((item: IRouteItem, key: any) => {
            return (
              <Link
                key={key}
                to={item.path}
                className={style.navBarList}
                onClick={() => setRouterIndex(key)}
              >
                <Icon
                  icon={item.icon}
                  defaultClassName={style.defaultIcon}
                  clickClassName={style.clickIcon}
                  clickIndex={routerIndex}
                  index={key}
                />
                <span
                  className={
                    routerIndex === key
                      ? style.clickNavName
                      : style.defaultNavName
                  }
                >
                  {item.name}
                </span>
              </Link>
            )
          })}
        </div>
      </div>
      <div className={style.main}>
        <Outlet />
      </div>
    </div>
  )
}
export default MainPage
