import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RouterIndex } from "../../dtos/router-index/index";
import useAuth from "../../hooks/useAuth";
import routes, { childrenProps } from "../../routes";
import enumArray from "enum-array";

export const useAction = () => {
  const [clickUser, setClickUser] = useState(null);
  const auth = useAuth();
  const location = useLocation();
  const [routerIndex, setRouterIndex] = useState<RouterIndex>();

  const handleClick = (event: any) => {
    setClickUser(event.currentTarget);
  };

  const routeChildren = routes.find((x) => x.path === "/home")?.children;
  const index = routeChildren?.findIndex(
    (x: childrenProps) => x.path === location.pathname
  );

  useEffect(() => {
    if (index !== RouterIndex.None) {
      setRouterIndex(
        enumArray.getEnumArray(RouterIndex).find((e) => e.key === index)
          ?.key as number
      );
    }
  }, [index]);

  return {
    clickUser,
    setClickUser,
    handleClick,
    auth,
    routerIndex,
    routeChildren,
  };
};
