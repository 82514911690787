import { useEffect, useState } from "react"
import { GetMediaData, PostAttachment } from "../../../../api/metashower"
import { IAttachmentResponseDto } from "../../../../dtos/generation-item"
import { SelectListHookProps } from "./props"

export const useAction = (props: SelectListHookProps) => {
  const { type } = props

  const [list, setList] = useState<IAttachmentResponseDto[]>([])

  const [count, setCount] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)

  const loadData = (page: number) => {
    setLoading(true)
    GetMediaData(type, {
      pageIndex: page,
      pageSize: 20,
    }).then(async (res) => {
      setCount(res?.count ?? 0)
      const attachmentIdList =
        res?.metaShowerGlobalMedias.map((item) => ({
          id: item.id,
          title: item.title,
          attachmentId: item.attachmentId,
        })) ?? []

      const uniqueArr = attachmentIdList.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.attachmentId === obj.attachmentId)
      )

      await PostAttachment({
        attachmentIds: uniqueArr.map((item) => item.attachmentId),
      }).then((res) => {
        if (res) {
          setList(
            res.map((item) => ({
              ...item,
              title:
                uniqueArr.find((uni) => uni.attachmentId === item.id)?.title ??
                "",
              mediaId:
                uniqueArr.find((uni) => uni.attachmentId === item.id)?.id ?? 0,
            }))
          )
        }
        setLoading(false)
      })
    })
  }

  useEffect(() => {
    loadData(1)
  }, [])

  return { list, count, loadData, loading }
}
