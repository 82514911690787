import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterIndex } from "../../dtos/router-index";
import routes, { childrenProps } from "../../routes";
import enumArray from "enum-array";

export const useAction = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const index = routes.findIndex(
    (x: childrenProps) => x.path === location.pathname
  );

  const [routerIndex, setRouterIndex] = useState<RouterIndex>(RouterIndex.Home);

  useEffect(() => {
    if (index !== RouterIndex.None) {
      setRouterIndex(
        enumArray.getEnumArray(RouterIndex).find((e) => e.key === index)
          ?.key as number
      );
    }
  }, [index]);

  useEffect(() => {
    navigate(location.pathname);
  }, [location.pathname]);

  return { routerIndex, setRouterIndex };
};
