import { useContext, useEffect, useState } from "react"
import { InitialAppSetting } from "./appsettings"
import { AuthContext } from "./hooks/providers/authProvider"

export const useAction = () => {
  const [show, setShow] = useState<boolean>(false)
  useContext(AuthContext)

  useEffect(() => {
    InitialAppSetting().then(() => setShow(true))
  }, [])
  return { show }
}
