import { IconProperty } from "../../dtos/icon";

const Icon = (props: IconProperty) => {
  return (
    <div
      className={`${props.icon} ${
        props.clickIndex === props.index
          ? props.clickClassName
          : props.defaultClassName
      }`}
    ></div>
  );
};

export default Icon;
