import { Alert, CircularProgress, Pagination, Snackbar } from "@mui/material";
import { createContext, Dispatch } from "react";
import {
  GetAttachUrl,
  ManageClickType,
  ShowToastTips,
} from "../../dtos/metashower";
import Avatar from "../avatar";
import UploadModal from "../upload-modal";
import { useAction } from "./hook";
import styles from "./index.module.scss";

interface PropsType {
  title: string;
  addTitle: string;
  pageType: number;
}

interface ShowType {
  showDialog: boolean;
  uploadSuccess: boolean;
  clickType: string;
}

interface IContext {
  state: ShowType;
  dispatch: Dispatch<{
    type: string;
    clickType: string;
    showDialog: boolean;
    payload?: Partial<ShowType>;
  }>;
}

const initValue: ShowType = {
  showDialog: false,
  uploadSuccess: false,
  clickType: "",
};

const ShowContext = createContext<IContext>({
  state: initValue,
  dispatch: () => {},
});

const Manage = (props: PropsType) => {
  const { title, addTitle, pageType } = props;

  const {
    mediasUrlList,
    state,
    dispatch,
    getPageNum,
    totalMsg,
    getAttachId,
    mediaIdList,
    loading,
    setLoading,
  } = useAction(pageType);

  return (
    <div className={styles.digitizing}>
      <div className={styles.title}>{title}</div>

      <div className={styles.list}>
        <div
          className={styles.avatarAdd}
          onClick={() =>
            dispatch({
              type: "clickAction",
              clickType: ManageClickType.Open,
              showDialog: true,
            })
          }
        >
          {addTitle}
        </div>
        <div className={styles.avatorList}>
          {mediasUrlList.length > 0 &&
            mediasUrlList.map((item: GetAttachUrl, index: number) => {
              return (
                <Avatar
                  item={item}
                  key={index}
                  getAttachId={getAttachId}
                  mediaId={
                    mediaIdList.find((x) => x.attachmentId === item.id)?.mediaId
                  }
                  setLoading={setLoading}
                />
              );
            })}
          {mediasUrlList.length === 0 ? (
            <div className={styles.noData}>暂无数据</div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <ShowContext.Provider value={{ state, dispatch }}>
        <UploadModal
          showDialog={state.showDialog}
          pageType={pageType}
          dialogTitle={addTitle}
        />
      </ShowContext.Provider>
      <div className={styles.pagination}>
        <div className={styles.paginationBox}>
          <Pagination
            count={totalMsg}
            onChange={getPageNum}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>

      <Snackbar
        open={loading}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert variant="standard" icon={false}>
          <CircularProgress
            sx={{
              color: "#7a7a7a",
              marginRight: "0.5rem",
            }}
            size={16}
          />
          删除中...
        </Alert>
      </Snackbar>
    </div>
  );
};

export { Manage, ShowContext };
