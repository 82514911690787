import { useContext, useState } from "react";
import { PostAttachId, PostUrlImg } from "../../api/metashower";
import { ManageClickType, ShowToastTips } from "../../dtos/metashower";
import { ShowContext } from "../manage";
import message from "../message/action";

export const useAction = (pagetype: number) => {
  const [inputVal, setInputVal] = useState<string>("");
  const [uploadId, setUploadId] = useState<number>(0);
  const [uploadImg, setUploadImg] = useState<string>("");
  const getContextVal = useContext(ShowContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState(false);

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length === 0) return;
    const file = e.target.files && e.target.files[0];
    e.target.value = "";
    const formData = new FormData();
    formData.append("file", file!);
    setLoading(true);
    const attachmentId = await PostUrlImg(formData);
    if (attachmentId) {
      setLoading(false);
      setUploadId(attachmentId.id);
      setUploadImg(attachmentId.fileUrl);
    }
  };

  const submitBtn = () => {
    if (uploadId === 0)
      return message.toast(ShowToastTips.UploadImg, 1500, "error", true);
    const data = {
      metaShowerGlobalMediaData: [
        (inputVal ?? "") !== ""
          ? {
              attachmentIds: [uploadId],
              mediaType: pagetype,
              title: inputVal,
              isPublic: isPublic,
            }
          : {
              attachmentIds: [uploadId],
              mediaType: pagetype,
              isPublic: isPublic,
            },
      ],
    };

    PostAttachId(data).then((res) => {
      if (res) {
        getContextVal.dispatch({
          type: "clickAction",
          clickType: ManageClickType.Upload,
          showDialog: false,
        });
        setInputVal("");
        setUploadImg("");
        setIsPublic(false);
        setUploadId(0);
      }
    });
  };

  const closeDialog = () => {
    setInputVal("");
    setUploadImg("");
    setIsPublic(false);
    setUploadId(0);
    getContextVal.dispatch({
      type: "clickAction",
      clickType: ManageClickType.Close,
      showDialog: false,
    });
  };

  const deleteImg = () => {
    setUploadImg("");
    setUploadId(0);
  };

  return {
    uploadFile,
    inputVal,
    setInputVal,
    getContextVal,
    submitBtn,
    uploadImg,
    closeDialog,
    deleteImg,
    loading,
    setLoading,
    setIsPublic,
    isPublic,
  };
};
