import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import Switch from "../../components/switch"
import { MetaShowerMediaType } from "../../dtos/generation-item"
import { SelectList } from "./component/select-list"
import { useAction } from "./hook"
import styles from "./index.module.scss"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Video = () => {
  return (
    <div className={styles.dialogVideoPreViewLayout}>
      <div className={styles.videoPreViewTitle}>视频画面预览</div>
      <div className={styles.dialogVideoPreView} />
    </div>
  )
}

const Generation = () => {
  const {
    voiceList,
    isShowed,
    modelChecked,
    inputValue,
    isInputNull,
    inputTitleValue,
    videoScale,
    language,
    open,
    setIsShowed,
    setModelChecked,
    setValue,
    onGenerate,
    setInputTitleValue,
    handleChangeVideoScale,
    handleChangeLanguage,
    handleDialogOpenClose,
    parameter,
    changeParameterState,
    setOperate,
    error,
    value,
    handleChange,
  } = useAction()

  const buttonStyle = {
    color: "white",
    borderRadius: "0.2rem",
    background: "#30cea3",
    padding: "0.2rem 1rem",
    opacity: "0.6",
    marginLeft: "1rem !important",
    ":hover": { background: "#30cea3", opacity: "1" },
  }

  return (
    <div className={styles.body}>
      <Snackbar
        message={error}
        open={isInputNull}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <div className={styles.title}>
        <div className={styles.titleLeft}>
          <input
            className={styles.titleLeftFont}
            placeholder="请输入视频名字"
            value={inputTitleValue}
            onChange={(v) => setInputTitleValue(v.target.value)}
            autoFocus={true}
          />
        </div>
        <div className={styles.titleRight}>
          <div className={styles.importText}>导入文本</div>
          <div className={styles.saveDraft}>保存草稿</div>
          <div className={styles.generateVideo} onClick={onGenerate}>
            <span>生成视频</span>
          </div>
        </div>
      </div>
      <div className={styles.editBackground}>
        <div className={styles.editLeft}>
          <div className={styles.topLeft}>
            <div className={styles.videoParam}>
              <div className={styles.videoScale}>
                <FormControl
                  size="small"
                  sx={{
                    width: "8rem",
                    minWidth: "3rem",
                    marginRight: "1.5rem",
                  }}
                >
                  <InputLabel id="demo-select-small">视频比例</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={videoScale}
                    onChange={handleChangeVideoScale}
                    label="视频比例"
                  >
                    <MenuItem value={"16:9"}>16:9</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" sx={{ minWidth: "9rem" }}>
                  <InputLabel id="demo-select-small">语言</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={language}
                    onChange={(e) =>
                      handleChangeLanguage(e.target.value as number)
                    }
                    label="语言"
                  >
                    {voiceList.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item.value}
                          sx={{
                            justifyContent: "center",
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => handleDialogOpenClose(true)}
                  sx={{ minWidth: "5rem" }}
                >
                  数字化身和背景选择
                </Button>
                <Dialog
                  open={open}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  disableEscapeKeyDown={true}
                  maxWidth="lg"
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid #ededed",
                      display: "flex",
                      width: "70rem",
                      boxSizing: "border-box",
                      padding: "0.5% 0.5% 0.5% 1%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "gray" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          centered
                        >
                          <Tab label="数字化身设置" {...a11yProps(0)} />
                          <Tab label="画面背景设置" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <Video />
                          <div style={{ flex: 1, paddingLeft: "0.5%" }}>
                            <SelectList
                              type={MetaShowerMediaType.Avatar}
                              changeParameterState={changeParameterState}
                              typeText={"digitalHuman"}
                              state={parameter.digitalHuman}
                            />
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Video />
                          <div style={{ flex: 1, paddingLeft: "0.5%" }}>
                            <SelectList
                              type={MetaShowerMediaType.Background}
                              changeParameterState={changeParameterState}
                              typeText={"background"}
                              state={parameter.background}
                            />
                          </div>
                        </div>
                      </TabPanel>
                    </Box>
                  </Box>
                  <DialogActions sx={{ padding: "1rem 1.5rem " }}>
                    <Box>
                      <Button
                        onClick={() => {
                          setOperate("cancel")
                          handleDialogOpenClose(false)
                        }}
                        sx={buttonStyle}
                      >
                        取消
                      </Button>
                      <Button
                        onClick={() => {
                          setOperate("add")
                          handleDialogOpenClose(false)
                        }}
                        autoFocus
                        sx={buttonStyle}
                      >
                        确定
                      </Button>
                    </Box>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
            <div className={styles.videoPreview} />
          </div>
          <div className={styles.buttomLeft}>
            <span className={styles.leftText}>显示字幕</span>
            <div className={styles.switch}>
              <Switch
                checked={isShowed}
                onChange={(isChecked: React.ChangeEvent<Element>) => {
                  setIsShowed(!isChecked)
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.editRight}>
          <div className={styles.topRight}>
            <textarea
              className={styles.inputText}
              placeholder="请在此处输入播报内容"
              id="inputText"
              value={inputValue}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>

          <div className={styles.buttomRight}>
            <span className={styles.rightText}>详情模式</span>
            <div className={styles.switch}>
              <Switch
                checked={modelChecked}
                onChange={(modelChecked: React.ChangeEvent<Element>) => {
                  setModelChecked(!modelChecked)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Generation
