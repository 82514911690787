import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import "./fonts/iconfont.css";
import Login from "./pages/login";
import routes from "./routes";
import { useAction } from "./App.hook";
import MainPage from "./pages/main";
import RequireAuth from "./pages/auth";
import { ArmsMonitor } from "./pages/arms";
import AuthProvider from "./hooks/providers/authProvider";
import React from "react";

const App = () => {
  const { show } = useAction();
  const AuthRoutes = () =>
    routes.map((item, index) => {
      return (
        <Route path={item.path} element={item.component} key={index}>
          <Route path="" element={<Navigate to={"/home/project"} />} />

          {item.children?.map((childrenItem, childrenIndex) => {
            return (
              <Route
                path={childrenItem.path}
                element={<RequireAuth>{childrenItem.component}</RequireAuth>}
                key={childrenIndex}
              />
            );
          })}
        </Route>
      );
    });

  const router = () => {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<MainPage />}>
          <Route path="" element={<Navigate to={"/home"} />} />
          {AuthRoutes()}
        </Route>
      </Routes>
    );
  };

  return show ? (
    <React.StrictMode>
      <BrowserRouter>
        <ArmsMonitor>
          <AuthProvider>{router()}</AuthProvider>
        </ArmsMonitor>
      </BrowserRouter>
    </React.StrictMode>
  ) : (
    <></>
  );
};

export default App;
