import { useEffect, useState } from "react";
import { SwitchProperty } from "../../dtos/switch";
import style from "./index.module.scss";

const Switch = (props: SwitchProperty) => {
  const { checked, onChange } = props;
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onInputChange = (e: any) => {
    const value = e.target.checked;
    setIsChecked(value);
    if (!onChange) {
      return;
    }
    onChange(value);
  };

  return (
    <label className={style.switch}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onInputChange}
        className={style.switchCheckbox}
      />
      <div
        className={
          isChecked
            ? `${style.switchContainer} ${style.switchContainerChecked}`
            : style.switchContainer
        }
      ></div>
    </label>
  );
};

export default Switch;
