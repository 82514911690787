import {
  IPostAttachmentParam,
  MediaDataDto,
  MetaShowerMediaType,
  IAttachmentResponseDto,
  MedaiDataPageIndex,
} from "../../dtos/generation-item";
import {
  MetaShowerBatchDto,
  AddMetaShowerCommand,
  GetAttachUrl,
  GetMediaType,
  MediasDataType,
  AttachmentId,
  PostMediaParams,
} from "../../dtos/metashower";

import { Get, Post } from "../http-client";

export const AddMetaShower = async (data: AddMetaShowerCommand) =>
  await Post<MetaShowerBatchDto>("/api/metashower/add", data);

export const GetListData = async (params: GetMediaType) =>
  await Get<MetaShowerBatchDto>(
    `/api/metashower/list?PageIndex=${params.PageIndex}&PageSize=${params.PageSize}`
  );

export const GetMediaData = async (
  data: MetaShowerMediaType,
  page: MedaiDataPageIndex
) =>
  await Get<MediaDataDto>(
    `/api/metashower/medias?PageIndex=${page.pageIndex}&PageSize=${page.pageSize}&MediaType=${data}`
  );

export const PostAttachment = async (data: IPostAttachmentParam) =>
  await Post<IAttachmentResponseDto[]>("/api/Attachment/attachments", data);

export const GetAttachId = async (params: GetMediaType) => {
  return await Get<MediasDataType>(
    `/api/MetaShower/medias?MediaType=${params.MediaType}&PageIndex=${params.PageIndex}&PageSize=${params.PageSize}`
  );
};

export const PostAttachId = async (data: PostMediaParams) => {
  return await Post<MediasDataType[]>(`/api/MetaShower/medias`, data);
};

export const GetMediasUrl = async (data: AttachmentId) =>
  await Post<GetAttachUrl[]>(`/api/Attachment/attachments`, data);

export const PostUrlImg = async (data: FormData) => {
  return await Post<GetAttachUrl>(`/api/Attachment/upload`, data);
};

export const DeleteMedia = async (mediaId: number) => {
  return await Post(`/api/MetaShower/media/delete?MediaId=${mediaId}`);
};
