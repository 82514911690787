import style from "./index.module.scss";
import { useAction } from "./hook";
import { Snackbar, Switch } from "@mui/material";

const Login = () => {
  const {
    setuserName,
    setPassword,
    handleClick,
    error,
    remember,
    setRemember,
    onEnter,
  } = useAction();

  return (
    <div className={style.wrap}>
      <Snackbar
        message="Incorrect username or password."
        open={error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <div className={style.loginBox}>
        <input
          className={style.inputTopWrap}
          placeholder={"UserName"}
          autoComplete="off"
          type="text"
          onChange={(e) => setuserName(e.target.value)}
          onKeyUp={(e) => onEnter(e.keyCode)}
        />
        <input
          className={style.inputWrap}
          placeholder={"Password"}
          autoComplete="on"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={(e) => onEnter(e.keyCode)}
        />
        <div className={style.rememberMeWrap}>
          <Switch
            checked={remember}
            onChange={(e) => {
              setRemember(e.target.checked);
            }}
          />
          <span className={style.rememberMeText}>Remember me</span>
        </div>
        <div className={style.signInButton} onClick={handleClick}>
          SIGN IN
        </div>
        <div className={style.titleWrap}>
          <span className={style.titleText}>Sign in</span>
        </div>
      </div>
    </div>
  );
};
export default Login;
