import style from "./index.module.scss";
import HighlightOff from "@mui/icons-material/HighlightOff";
import { ModalVideoProps } from "../../dtos/modal-video";

export const ModalVideo = (props: ModalVideoProps) => {
  const { videoUrl, onCancel, type, videoText } = props;
  return (
    <>
      {type === "video" && (
        <div className={style.modalBox}>
          <div className={style.videoBoxWrap}>
            <video controls={true} className={style.videoBox} autoPlay>
              <source src={videoUrl} type="video/mp4" />
            </video>
            <div onClick={() => onCancel(type)} className={style.cancle}>
              <HighlightOff className={style.icon} />
            </div>
          </div>
        </div>
      )}
      {type === "text" && (
        <div className={style.modalBox} onClick={() => onCancel("text")}>
          <div className={style.videoBoxWrap}>
            <div className={style.textBox}>
              <span className={style.textWrap}>
                文字内容：{videoText ? videoText : "暂无内容"}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
