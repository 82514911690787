import { green } from "@mui/material/colors"
import React from "react"
import { useEffect, useState } from "react"
import { GetListData } from "../../../../api/metashower"
import {
  MediaPage,
  MetaShowerBatches,
  MetaShowerBatchStatus,
} from "../../../../dtos/metashower"

export const useAction = () => {
  const [list, setList] = useState<MetaShowerBatches[]>()
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [isShowVideo, setIsShowVideo] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const [clickDetailIcon, setClickDetailIcon] = useState(false)
  const [clickIndex, setClickIndex] = useState<number>()
  const [clickDetail, setClickDetail] = useState(false)
  const [videoText, setText] = useState("")
  const [totalMsg, settotalMsg] = useState<number>(1)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const timer = React.useRef<number>()

  const fetchLoadData = async () => {
    await GetListData({
      PageIndex: pageIndex,
      PageSize: MediaPage.PageSize,
    })
      .then((res) => {
        if ((res ?? "") !== "") {
          const itemList = res?.metaShowerBatches.filter((x) => !!x.text)
          const total: number = Math.ceil(res?.count! / MediaPage.PageSize)
          settotalMsg(total)
          setList(itemList)
          timer.current = window.setTimeout(() => {
            setSuccess(false)
          }, 2000)
        }
      })
      .catch((err) => {
        timer.current = window.setTimeout(() => {
          setSuccess(false)
        }, 2000)
      })
  }

  const buttonRefresh = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  }

  const clickVideo = (item: MetaShowerBatches) => {
    if (item.status === MetaShowerBatchStatus.Completed) {
      setIsShowVideo(!isShowVideo)
      setVideoUrl(item.videoUrl)
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleButtonClick = () => {
    if (isShowVideo) {
      return
    }
    if (!loading) {
      setSuccess(false)
      setLoading(true)
      timer.current = window.setTimeout(() => {
        fetchLoadData()
        setSuccess(true)
        setLoading(false)
      }, 2000)
    }
  }

  const onCancel = (type: string) => {
    if (type === "video") {
      setVideoUrl("")
      setIsShowVideo(!isShowVideo)
    } else {
      setClickDetail(!clickDetail)
      setText("")
    }
  }

  const onDetailIcon = (index: number) => {
    setClickDetailIcon(!clickDetailIcon)
    setClickIndex(index)
  }

  const onDetail = (text: string) => {
    setClickDetail(!clickDetail)
    setText(text)
    setClickDetailIcon(!clickDetailIcon)
  }

  const getPageNum = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageIndex(page)
  }

  useEffect(() => {
    fetchLoadData()
  }, [pageIndex])

  return {
    list,
    handleButtonClick,
    buttonRefresh,
    success,
    loading,
    clickVideo,
    videoUrl,
    isShowVideo,
    onCancel,
    onDetailIcon,
    clickDetailIcon,
    onDetail,
    videoText,
    clickDetail,
    clickIndex,
    setClickDetailIcon,
    totalMsg,
    getPageNum,
    pageIndex,
  }
}
