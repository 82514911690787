import { HighlightOff } from "@mui/icons-material";
import { Dispatch, useState } from "react";
import { DeleteMedia } from "../../api/metashower";
import { GetAttachUrl } from "../../dtos/metashower";
import message from "../message/action";
import styles from "./index.module.scss";

const Avatar = (props: {
  item: GetAttachUrl;
  getAttachId: () => Promise<void>;
  mediaId: number | undefined;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { item, getAttachId, mediaId, setLoading } = props;
  const [showUI, setShowUI] = useState(false);
  return (
    <div className={styles.box}>
      <div className={styles.avatarBox}>
        <div
          className={styles.imgBox}
          onMouseEnter={() => setShowUI(true)}
          onMouseLeave={() => setShowUI(false)}
        >
          {showUI && (
            <HighlightOff
              className={styles.cancle}
              onClick={() => {
                if (!!mediaId) {
                  setLoading(true);
                  DeleteMedia(mediaId)
                    .then(() => {
                      getAttachId().then(() => {
                        setLoading(false);
                        message.toast("删除成功", 1500, "success", true);
                      });
                    })
                    .catch(() => {
                      setLoading(false);
                      message.toast("删除失败", 1500, "error", true);
                    });
                }
              }}
            />
          )}
          <img src={item.fileUrl} alt="" className={styles.avatarImg} />
        </div>
      </div>
      <div className={styles.avatarName}>{item.title}</div>
    </div>
  );
};

export default Avatar;
