import { Manage } from "../../components/manage"
import { MetaShowerType, UploadTitle } from "../../dtos/metashower"

const Digitizing = () => {
  return (
    <Manage
      title="数字化身"
      addTitle={UploadTitle.Avatar}
      pageType={MetaShowerType.Avatar}
    />
  )
}

export default Digitizing
