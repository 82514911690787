import { Button, Menu, MenuItem } from "@mui/material"
import { Link, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import style from "../home/index.module.scss"
import { useAction } from "./hook"

const Home = () => {
  const {
    clickUser,
    setClickUser,
    handleClick,
    auth,
    routerIndex,
    routeChildren,
  } = useAction()
  const { username } = useAuth()

  return (
    <div className={style.default}>
      <div className={style.top}>
        <div className={style.title}>
          <div className={style.titleLeft}>
            {routeChildren?.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.path}
                  className={
                    routerIndex === index
                      ? style.clickTitle
                      : style.defaultTitle
                  }
                >
                  {item.name}
                </Link>
              )
            })}
          </div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={style.titleRight}
          >
            <div
              className={`${"iconfont icon-yonghu"} ${style.userIcon}`}
              onClick={() => setClickUser(null)}
            />
          </Button>
          <Menu
            open={clickUser !== null}
            onClose={() => setClickUser(null)}
            anchorEl={clickUser}
          >
            <MenuItem disabled>{username}</MenuItem>
            <MenuItem onClick={() => auth.signout()}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export default Home
