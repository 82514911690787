import { createRoot } from "react-dom/client"
import Message from "./index"

type AlertColor = "success" | "info" | "warning" | "error"

const message = {
  toast: (
    text: string,
    time: number,
    tipsType: AlertColor,
    showMsg: boolean
  ) => {
    const JSXdom: JSX.Element = (
      <Message
        content={text}
        duration={time}
        type={tipsType}
        showMsg={showMsg}
      />
    )
    const dom: HTMLDivElement = document.createElement("div")
    createRoot(dom).render(JSXdom)
    document.body.appendChild(dom)
  },
}

export default message
