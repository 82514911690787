import { Manage } from "../../components/manage"
import { MetaShowerType, UploadTitle } from "../../dtos/metashower"

const Management = () => {
  return (
    <Manage
      title="背景管理"
      addTitle={UploadTitle.BackgroundTitle}
      pageType={MetaShowerType.Background}
    />
  )
}

export default Management
