import { CircularProgress, Pagination } from "@mui/material"
import { MetaShowerMediaType } from "../../../../dtos/generation-item"
import { useAction } from "./hook"
import styles from "./index.module.scss"
import { SelectListProps } from "./props"

export const SelectList = (props: SelectListProps) => {
  const { type, changeParameterState, typeText, state } = props

  const { list, count, loadData, loading } = useAction({ type })

  return (
    <div className={styles.box}>
      <div className={styles.title}>
        {type === MetaShowerMediaType.Avatar ? "选择数字化身" : "选择画面背景"}
      </div>
      <div className={styles.list}>
        {loading ? (
          <CircularProgress className={styles.cron} />
        ) : (
          <div className={styles.image_box}>
            {list.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    state?.id === item.id ? styles.item_hover : styles.item
                  } ${styles.item_same}`}
                  onClick={() => {
                    changeParameterState(typeText, item)
                  }}
                >
                  <img
                    className={styles.image}
                    src={item.fileUrl}
                    alt={item.fileName}
                  />
                  <div
                    className={`${
                      state?.id === item.id
                        ? styles.content_hover
                        : styles.content
                    } ${styles.contentSame}`}
                  >
                    {item.title}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={Math.ceil(count / 20)}
          variant="outlined"
          shape="rounded"
          defaultPage={1}
          onChange={(_, page) => loadData(page)}
        />
      </div>
    </div>
  )
}
