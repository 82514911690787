import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Post } from "../../api/http-client";
import useAuth from "../../hooks/useAuth";

export const useAction = () => {
  const { signin } = useAuth();
  let navigate = useNavigate();
  let location = useLocation();
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const [error, setError] = useState(false);
  setTimeout(() => setError(false), 10000);

  const historyCallback = () => {
    let p = location.state as any;
    p?.from?.pathname
      ? navigate(p.from.pathname, { replace: true })
      : navigate("/home");
  };

  const handleClick = () => {
    Post<string>("/auth/login", {
      username: userName,
      password: password,
    }).then((token) => {
      if (token) {
        if (remember) {
          localStorage.setItem("token", token);
        }
        signin(token, historyCallback);
      } else {
        setError(true);
      }
    });
  };

  const onEnter = (keyCode: number) => {
    keyCode === 13 && !!userName && !!password && handleClick();
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (token) {
      signin(token, historyCallback);
    }
  }, []);

  return {
    setuserName,
    setPassword,
    handleClick,
    error,
    setError,
    remember,
    setRemember,
    onEnter,
  };
};
