import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AddMetaShower } from "../../api/metashower"
import { v4 as uuidv4 } from "uuid"
import { SelectChangeEvent } from "@mui/material"
import {
  IAchmentIdTitle,
  IAttachmentResponseDto,
  IParamSummaryDto,
} from "../../dtos/generation-item"
import { LanguageVoiceType } from "../../dtos/metashower"
import { clone } from "ramda"

const voiceList = [
  {
    value: LanguageVoiceType.MandarinMale,
    label: "普通话-男声",
  },
  {
    value: LanguageVoiceType.MandarinFemale,
    label: "普通话-女声",
  },
  {
    value: LanguageVoiceType.AmericanEnglishMale,
    label: "美式英语-男声",
  },
  {
    value: LanguageVoiceType.AmericanEnglishFemale,
    label: "美式英语-女声",
  },
  {
    value: LanguageVoiceType.CantoneseFemale,
    label: "粤语-女声",
  },
  {
    value: LanguageVoiceType.JapaneseMale,
    label: "日语-男声",
  },
  {
    value: LanguageVoiceType.JapaneseFemale,
    label: "日语-女声",
  },
]

export const useAction = () => {
  // switch开关初始状态
  const [isShowed, setIsShowed] = useState<boolean>(false)
  // switch开关状态
  const [modelChecked, setModelChecked] = useState<boolean>(false)
  // 视频内容
  const [inputValue, setValue] = useState<string>("")
  // 视频内容是否为空
  const [isInputNull, setIsInputNull] = useState<boolean>(false)
  // 视频标题
  const [inputTitleValue, setInputTitleValue] = useState<string>("未命名项目")
  // 视频比例
  const [videoScale, setVideoScale] = useState<string>("16:9")
  // 视频语言
  const [language, setLanguage] = useState<LanguageVoiceType>(
    LanguageVoiceType.MandarinMale
  )
  // dialog对话框开关状态
  const [open, setOpen] = useState<boolean>(false)

  const [parameter, setParameter] = useState<{
    digitalHuman: IAttachmentResponseDto | null
    background: IAttachmentResponseDto | null
  }>({
    digitalHuman: null,
    background: null,
  })

  const [originalParameter, setOriginalParameter] = useState<{
    digitalHuman: IAttachmentResponseDto | null
    background: IAttachmentResponseDto | null
  }>({
    digitalHuman: null,
    background: null,
  })

  const [operate, setOperate] = useState<"add" | "cancel" | "">("")

  const [error, setError] = useState<string>("")

  const [value, setTab] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const changeParameterState = (
    key: keyof {
      digitalHuman: IAttachmentResponseDto | null
      background: IAttachmentResponseDto | null
    },
    value: IAttachmentResponseDto
  ) => {
    setParameter((prev) => {
      if (prev[key] === value) {
        return { ...prev, [key]: null }
      } else return { ...prev, [key]: value }
    })
  }

  const handleDialogOpenClose = (boolean: boolean) => setOpen(boolean)

  let navigate = useNavigate()

  const onGenerate = () => {
    if (inputValue.length === 0) {
      setIsInputNull(true)
      setError("没有输入文本")
    } else if (
      parameter.digitalHuman === null ||
      parameter.background === null
    ) {
      setIsInputNull(true)
      if (parameter.digitalHuman === null) {
        setError("未选择数字化身")
      } else {
        setError("未选择画面背景")
      }
    } else if (
      parameter.digitalHuman.fileName &&
      parameter.digitalHuman.fileName.split(".")[
        parameter.digitalHuman.fileName.split(".").length - 1
      ] !== "png"
    ) {
      setIsInputNull(true)
      setError("选择的数字化身图片不是PNG类型")
    } else {
      AddMetaShower({
        uuid: uuidv4(),
        text: inputValue,
        title: inputTitleValue,
        language: language,
        mediaIds: idsArray,
      }).then(() => {
        navigate("/home")
        setOperate("")
        setParameter({
          digitalHuman: null,
          background: null,
        })
      })
    }
  }

  const handleChangeVideoScale = (event: SelectChangeEvent) =>
    setVideoScale(event.target.value as string)

  const handleChangeLanguage = (state: number) => {
    setLanguage(state)
  }

  const idsArray = useMemo(() => {
    const ids: number[] = []
    Object.values(parameter).map((item) => {
      if (item !== null) {
        ids.push(item.mediaId)
      }
    })
    return ids
  }, [parameter])

  useEffect(() => {
    if (isInputNull) {
      setTimeout(() => setIsInputNull(false), 3000)
    }
  }, [isInputNull])

  useEffect(() => {
    if (open) {
      const oldParameter = clone(parameter)
      setOriginalParameter(oldParameter)
    } else {
      if (operate === "add") {
        return
      } else if (operate === "cancel") {
        setParameter(originalParameter)
      }
    }
  }, [open])

  return {
    voiceList,
    isShowed,
    modelChecked,
    inputValue,
    isInputNull,
    inputTitleValue,
    videoScale,
    language,
    open,
    setIsShowed,
    setModelChecked,
    setValue,
    onGenerate,
    setInputTitleValue,
    handleChangeVideoScale,
    handleChangeLanguage,
    handleDialogOpenClose,
    changeParameterState,
    parameter,
    setOperate,
    error,
    value,
    handleChange,
  }
}
